<template>
    <section class="app-ecommerce-details">
        <!-- Alert: No item found -->

        <b-overlay :show="formShow" rounded="sm" no-fade>
            <b-card>
                <b-row>
                    <b-col cols="12" xl="3" md="3" sm="3" v-if="employeeId == undefined">
                        <b-form-group label="Employee" label-for="Employee">
                            <v-select @input="getAvailabilityData" v-model="selectedEmployee" :options="users" label="full_name" input-id="customer-data" :clearable="false"> </v-select>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" :xl="employeeId == undefined ? 3 : 6" :md="employeeId == undefined ? 3 : 6" :sm="employeeId == undefined ? 3 : 6">
                        <b-form-group label="Data Range" label-for="Data Range">
                            <flat-pickr @input="getAvailabilityData" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" xl="3" md="3" sm="3">
                        <b-button @click="exportPdf" class="btn-icon ml-auto mt-2" block variant="primary">
                            Export as PDF
                        </b-button>
                    </b-col>

                    <b-col cols="12" xl="3" md="3" sm="3">
                        <b-button @click="exportExcel" class="btn-icon ml-auto mt-2" block variant="primary">
                            Export as Excel
                        </b-button>
                    </b-col>
                </b-row>

                <b-row cols="12" class="mt-2">
                    <!-- solid color -->
                    <b-col md="6" xl="3">
                        <b-card class="text-center bg-gradient-success">
                            <b-card-text>
                                {{ totalWorked }}
                            </b-card-text>
                            <b-card-text>
                                Total Days Worked
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- solid color -->
                    <b-col md="6" xl="3">
                        <b-card class="text-center bg-gradient-danger">
                            <b-card-text>
                                {{ totalSick }}
                            </b-card-text>
                            <b-card-text>
                                Total Sick Leave
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- solid color -->
                    <b-col md="6" xl="3">
                        <b-card class="text-center bg-gradient-info">
                            <b-card-text>
                                {{ totalHoliday }}
                            </b-card-text>
                            <b-card-text>
                                Total Holidays
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <b-col md="6" xl="3">
                        <b-card class="text-center bg-gradient-warning">
                            <b-card-text>
                                {{ totalAwol }}
                            </b-card-text>
                            <b-card-text>
                                Total AWOL
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xl="12" md="12">
                        <!-- Vehiclle Image and Name -->
                        <b-card-body>
                            <b-row class="mt-2">
                                <b-col cols="12">
                                    <b-table
                                        striped
                                        hover
                                        ref="refUserListTable"
                                        class="position-relative"
                                        :items="availabilityData"
                                        filter-debounce="250"
                                        responsive
                                        :small="true"
                                        head-variant="dark"
                                        :fields="tableColumns"
                                        show-empty
                                        empty-text="No matching records found"
                                    >
                                        <template #cell(userId)="data">
                                            {{ data.item.full_name }}
                                        </template>

                                        <template #cell(date)="data">
                                            {{ dateFormat(data.value) }}
                                        </template>

                                        <template #cell(status)="data">
                                            <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                                Not Worked
                                            </b-badge>

                                            <b-badge v-if="data.value == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                                                Sick
                                            </b-badge>

                                            <b-badge v-if="data.value == 'H'" pill :variant="`info`" class="text-capitalize">
                                                Holiday
                                            </b-badge>

                                            <b-badge v-if="data.value == 'E'" pill :variant="`primary`" class="text-capitalize">
                                                Worked
                                            </b-badge>

                                            <b-badge v-if="data.value == 'O'" pill :variant="`light-primary`" class="text-capitalize">
                                                Worked Overtime
                                            </b-badge>

                                            <b-badge v-if="data.value == 'W'" pill :variant="`danger`" class="text-capitalize">
                                                AWOL
                                            </b-badge>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-col>

                    <b-col cols="12" xl="2" md="3"> </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText, BProgress } from "bootstrap-vue";
import moment from "moment";
import employeeStoreModule from "../employeeStoreModule";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import ProfileCropper from "@core/components/image-cropper/ProfileCropper.vue";
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';

// import { avatarText } from '@core/utils/filter'

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BButton,
        BAvatar,
        BModal,
        BCardBody,
        BImg,
        BCardHeader,
        BCardTitle,
        BTabs,
        BCardText,
        BTab,
        ProfileCropper,
        BProgress,
        flatPickr,
        vSelect,
    },

    props: {
        employeeId: {
            required: false,
        },
    },

    setup() {
        const EMPLOYEE_APP_STORE_MODULE_NAME = "employee";

        // Register module
        if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var employeeData = {
            timeline: [],

            profile: null,
            id: null,
            name: null,
            surname: null,
            email: null,
            role: null,
            phone: null,
            address1: null,
            address2: null,
            city: null,
            post_code: null,
            dob: null,
            ni_no: null,
            passport_no: null,
            address_time: null,
            ipaf_expiry: null,
            passport_expiry: null,
            driving_license: null,
            day_rate: null,
            weekend_rate: null,
            lodging: null,
            day_rate_glo: null,
            weekend_rate_glo: null,
            forklift_license: null,
            cscs: null,
            is_driver: false,
        };

        var availabilityData = [
            {
                userId: null,
                date: null,
                status: null,
            },
        ];

        const availabilityOptions = [
            { label: "Available", value: "A" },
            { label: "Holiday", value: "H" },
            { label: "Sick", value: "S" },
            { label: "AWOL", value: "W" },
        ];
        return {
            employeeData: employeeData,

            totalWorked: 0,
            totalSick: 0,
            totalAwol: 0,
            totalHoliday: 0,

            imageUrl: store.state.app.profileURL + "none.jpg",
            tempImage: null,
            profileURL: store.state.app.profileURL,
            selectedImage: null,
            croppedImage: null,
            userData: {},
            percentCompleted: 0,
            selectedEmployee: null,
            availabilityData: availabilityData,
            baseURL: store.state.app.baseURL,

            roleOptions: [],
            availabilityOptions,

            formShow: false,
            permissions: [],
            users: [],
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth() + 1,
            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            fetchAvailability: [],

            //FILES
            where: router.currentRoute.name,
            files: [],
            selectFileType: true,
            fileLink: null,
            modalId: null,
            modalFileName: null,
            modalFile: null,
            download: false,
            fileURL: store.state.app.fileURL,
            percentCompleted: 0,

            fetchTeams: [],
            totalRows: 0,

            mainProps: {
                blank: true,
                blankColor: "#777",
                width: 75,
                height: 75,
                class: "m1",
            },

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            config: {
                dateFormat: "d-m-Y",
                mode: "range",
                locale: { firstDayOfWeek: 1 },
            },

            perYearOptions: [2023, 2024],
            perYear: 2023,

            tableColumns: [
                { key: "userId", label: "USER NAME", sortable: true, class: "text-center" },
                { key: "date", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
            ],

            teamTableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "title", sortable: true, class: "text-center" },
                { key: "foreman", sortable: true, class: "text-center" },
                { key: "staff_count", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
            ],
        };
    },

    created() {
        var dateNow = new Date();
        var firstDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("DD-MM-YYYY");
        var lastDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("DD-MM-YYYY");
        this.rangeDate = String(firstDate) + " to " + String(lastDate);
        this.getAvailabilityData();
        //this.selectedEmployee = this.employeeId;
    },

    methods: {
        editEmployee() {
            router.push({ name: "employee-edit", params: { id: router.currentRoute.params.id } });
        },

        viewEmployee(id) {
            this.formShow = true;
            store
                .dispatch("employee/fetchEmployee", { id: id })
                .then((response) => {
                    this.employeeData = response.data;
                    this.fetchAvailability = response.data.availabilityList;
                    this.fetchTeams = response.data.fetchTeams;
                    this.totalRows = this.fetchTeams.length;
                    this.files = response.data.get_files;
                    this.users = response.data.userList;
                    this.imageUrl = this.profileURL + response.data.profile;

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to retrieve employee data. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
            router.push({ name: "employee-view", params: { id: id } });
        },

        selectEmployee() {},

        exportExcel() {
            this.formShow = true;

            var tempData = {
                startDate: null,
                endDate: null,
                userId: null,
            };

            if (this.employeeId == undefined) {
                tempData.userId = this.selectedEmployee ? this.selectedEmployee.id : null;
            } else {
                tempData.userId = this.employeeId;
            }

            if (this.rangeDate != null) {
                if (this.rangeDate.includes("to")) {
                    tempData.startDate = this.rangeDate.split(" to ")[0];
                    tempData.endDate = this.rangeDate.split(" to ")[1];
                } else {
                    tempData.startDate = this.rangeDate;
                }
            }
            store
                .dispatch("employee/exportExcel", tempData)
                .then((response) => {
                    let blob = new Blob([response.data], {
                        type: "application/octet-stream",
                    });

                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "availability.xls");
                    document.body.appendChild(link);
                    link.click();

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to create report. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
        },

        exportPdf() {
            this.formShow = true;

            var tempData = {
                startDate: null,
                endDate: null,
                userId: null,
            };

            if (this.employeeId == undefined) {
                tempData.userId = this.selectedEmployee ? this.selectedEmployee.id : null;
            } else {
                tempData.userId = this.employeeId;
            }

            if (this.rangeDate != null) {
                if (this.rangeDate.includes("to")) {
                    tempData.startDate = this.rangeDate.split(" to ")[0];
                    tempData.endDate = this.rangeDate.split(" to ")[1];
                } else {
                    tempData.startDate = this.rangeDate;
                }
            }
            store
                .dispatch("employee/exportPdf", tempData)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Availability_Report" + Number(this.selectedEmployee.id) + ".pdf");
                    document.body.appendChild(link);
                    link.click();
                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to create report. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
        },

        getAvailabilityData() {
            this.formShow = true;

            var tempData = {
                startDate: null,
                endDate: null,
                userId: null,
            };

            if (this.employeeId == undefined) {
                tempData.userId = this.selectedEmployee ? this.selectedEmployee.id : null;
            } else {
                tempData.userId = this.employeeId;
            }

            if (this.rangeDate != null) {
                if (this.rangeDate.includes("to")) {
                    tempData.startDate = this.rangeDate.split(" to ")[0];
                    tempData.endDate = this.rangeDate.split(" to ")[1];
                } else {
                    tempData.startDate = this.rangeDate;
                }
            }
            store
                .dispatch("employee/availabilityData", tempData)
                .then((response) => {
                    this.availabilityData = response.data.data;
                    this.users = response.data.userList;
                    if (this.selectedEmployee == null) {
                        // if (this.employeeId == undefined) {
                        this.selectedEmployee = this.users[0];
                        // } else {
                        //   this.selectedEmployee = this.users.find((user) => (user.id = this.employeeId));
                        // }
                    }

                    this.totalWorked = response.data.totalWorked;
                    this.totalSick = response.data.totalSick;
                    this.totalAwol = response.data.totalAwol;
                    this.totalHoliday = response.data.totalHoliday;

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to create report. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
        },

        clearDate() {
            if (this.rangeDate != null && this.rangeDate != "") {
                this.startDate = null;
                this.endDate = null;
                this.rangeDate = null;
            }
        },

        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
        },
    },

    computed: {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
    stroke: black;
    fill: none;
    width: 36px;
    height: 36px;
    stroke-width: 0.6666666666666666;
}

.user-title {
    width: 100%;
    font-weight: bold;
}

.user-badge {
    width: 50%;
    align-content: center;
}
</style>
